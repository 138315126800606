<template>
  <!-- 已收藏模板 -->
  <div class="collectedTemp">
    <h3 class="title">我的模板</h3>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="收藏的模板" name="first">
        <ul v-if="collectList.length">
          <li v-for="(item, index) in collectList" :key="index" @mouseleave="current = null" @mouseenter="current = index">
            <img :src="JSON.parse(item.page).activityBase.cover" alt="" v-if="item.page">
            <!-- <p>{{ item.name }}</p> -->
            <p class="name">{{ item.name }}</p>
            <div class="type">
              <span class="price" v-if="item.isFree">免费</span>
              <span class="price" v-else-if="item.templeStatus == 1">限免</span>
              <span class="price" v-else>{{ '￥' + item.sellingPrice + '元' }}</span>
              <span class="money">{{ item.originalPrice ? '￥' + item.originalPrice + '元' : '' }}</span>
              <span class="line">|</span>
              <span class="type-text">{{ typeList[item.type] }}</span>
            </div>
            <div class="mask" :class="{active: index == current}">
              <i class="el-icon-star-on" @click.stop="collectActive(item.templeId)"></i>
            </div>
            <div class="activeSet" :class="{active: index == current}" v-if="item.putAwayStatus != 2">
              <active-qrcode :url="`${item.demoUrl}?temple=${item.templeId}`" v-if="item.demoUrl" class="code" codeSize="100"></active-qrcode>
              <div v-else class="code"></div>
              <el-button type="primary" @click="createAct(item.templeId, false)">创建活动</el-button>
            </div>
            <div class="noActive" v-if="item.putAwayStatus == 2">已下架</div>
          </li>
        </ul>
        <el-pagination
          v-if="totalPage"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[12, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper"
          style="text-align: right; margin-top: 30px">
        </el-pagination>
        <div style="height: 500px" v-else>
          <listNull text="你还没有收藏的模板呢~" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="已购买的模板" name="second">
        <ul v-if="buyList.length">
          <li v-for="(item, index) in buyList" :key="index" @mouseleave="current = null" @mouseenter="current = index">
            <img :src="JSON.parse(item.page).activityBase.cover" alt="" v-if="item.page">
            <p class="name">{{ item.templeName }}</p>
            <div class="type">
              <!-- <span class="price">{{ '￥' + item.payAmount + '元' }}</span>
              <span class="line">|</span> -->
              <span class="type-text">{{ typeList[item.templeType] }}</span>
            </div>
            <div class="activeSet" :class="{active: index == current}">
              <active-qrcode :url="`${item.templeDemoUrl}?temple=${item.templeId}`" v-if="item.templeDemoUrl" class="code" codeSize="100"></active-qrcode>
              <div v-else class="code"></div>
              <el-button type="primary" @click="createAct(item.templeId, true)">创建活动</el-button>
            </div>
            <!-- <div class="buy">已购</div> -->
          </li>
        </ul>
        <el-pagination
          v-if="totalPage1"
          @size-change="sizeChangeHandle1"
          @current-change="currentChangeHandle1"
          :current-page="pageIndex1"
          :page-sizes="[12, 20, 50, 100]"
          :page-size="pageSize1"
          :total="totalPage1"
          layout="total, sizes, prev, pager, next, jumper"
          style="text-align: right; margin-top: 30px">
        </el-pagination>
        <div style="height: 500px" v-else>
          <listNull text="你还没有已购买的模板呢~" />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getCollectList, delCollect, buyTemplate } from '@/api/market'
import listNull from '@/components/listNull.vue'
import ActiveQrcode from '@/components/ActiveQrcode.vue'

export default {
  data () {
    return {
      current: null,
      pageIndex: 1,
      pageSize: 12,
      totalPage: 0,
      collectList: [],
      isShow: false,
      typeList: ['开宝箱', '九宫格', '砸金蛋', '大转盘', '小兔兔奔月记', '红旗护卫', '画金蛋', '幸运大翻转', '欢乐抽奖机', '阅读有礼', '二维码九宫格', '刮刮乐', '投壶', '潮前看'],
      activeName: 'first',
      pageIndex1: 1,
      pageSize1: 12,
      totalPage1: 0,
      buyList: []
    }
  },
  components:{listNull, ActiveQrcode},
  created () {
    this.getCollectData()
  },
  methods: {
    handleClick(tab, event) {
      if(this.activeName == 'first') {
        this.pageIndex = 1
        this.getCollectData()
      }else if(this.activeName == 'second') {
        this.pageIndex1 = 1
        this.getBuy()
      }
    },
    // 获取收藏列表数据
    async getCollectData () {
      this.isShow = false
      const res = await getCollectList({pageSize: this.pageSize, pageNO: this.pageIndex})
      if(res.code == 200) {
        this.collectList = res.data.list ? res.data.list : []
        this.totalPage = res.data.total
        this.isShow = true
      }
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getCollectData()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getCollectData()
    },
    // 取消收藏
    async collectActive (templeId) {
      const res = await delCollect({templeId})
      if(res.code == 200) {
        this.$message.success('取消收藏成功')
        this.getCollectData()
      }
    },
    // 点击创建
    createAct(templeId, isBuy) {
      let { href } = this.$router.resolve({ path: '/MarketingActivityEdit', query: {id: templeId, isBuy } })
      window.open(href, '_blank')
    },
    // 获取已购买列表
    async getBuy () {
      console.log(this.pageSize1, this.pageIndex1)
      const res = await buyTemplate({pageNo: this.pageIndex1, pageSize: this.pageSize1})
      if(res.code === 200) {
        this.buyList = res.data.list ? res.data.list : []
        this.totalPage1 = res.data.total
      }
    },
    // 每页数
    sizeChangeHandle1 (val) {
      this.pageSize1 = val
      this.pageIndex1 = 1
      this.getBuy()
    },
    // 当前页
    currentChangeHandle1 (val) {
      this.pageIndex1 = val
      this.getBuy()
    },
  }
}
</script>

<style lang="scss" scoped>
  .collectedTemp {
    padding: 30px;
    .title {
      margin: 20px 0;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      li {
        position: relative;
        width: 215px;
        height: 440px;
        text-align: center;
        font-size: 14px;
        border: 1px solid #ccc;
        margin: 0 20px 20px 0;
        transition: all .2s linear;
        &:hover{
          box-shadow: 0 4px 12px 0 rgba(0,0,0,.08);
          transform: translate3d(0,-4px,0);
        }
        img {
          width: 100%;
          height: 382px;
        }
        .noActive {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 382px;
          background-color: rgba(107, 106, 104, .6);
          text-align: center;
          line-height: 382px;
          font-size: 32px;
          color: #ddd;
        }
        .mask {
          position: absolute;
          left: 0;
          top: 0;
          display: none;
          width: 100%;
          height: 382px;
          background-color: rgba(0, 0, 0, .3);
          z-index: 10;
          i {
            position: absolute;
            right: 10px;
            top: 20px;
            cursor: pointer;
            width: 28px;
            height: 28px;
            text-align: center;
            line-height: 28px;
            font-size: 18px;
            background-color: #fff;
            border-radius: 4px;
            color: #ffd400;
          }
        }
        .active {
          display: block !important;
        }
        .activeSet {
          position: absolute;
          top: 30%;
          left: 30%;
          display: none;
          z-index: 10;
          .code {
            width: 100px;
            height: 100px;
            margin-bottom: 10px;
          }
        }
        .name {
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #33383E;
          font-size: 14px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          margin: 5px 10px 0;
        }
        .type {
          display: flex;
          color: #C8C8C8;
          font-size: 12px;
          height: 20px;
          align-items: center;
          justify-content: center;
          .price {
            color: #EE0C19;
          }
          .money {
            text-decoration: line-through;
          }
          .type-text {
            color: #888;
          }
          .line {
            color: #888;
            margin: 0 5px;
          }
        }
        // .buy {
        //   position: absolute;
        //   left: 10px;
        //   top: 20px;
        //   padding: 3px;
        //   background-color: #eec484;
        //   border-radius: 5px;
        //   font-size: 12px;
        // }
      }
    }
  }
</style>